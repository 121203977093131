<template>
  <div id="print" ref="print">
    <v-container class="text-center pa-3">
      <template v-if="activated">
        <div class="row">
          <div class="col-12">
            <v-card-text v-if="!empty(event)">
              <div>You request has been received, we will respond soon.</div>
            </v-card-text>
          </div>
        </div>
      </template>
      <v-overlay :absolute="false" :value="loading">
        <v-progress-circular
          :size="200"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import Swal from "sweetalert2";
export default {
  created() {
    this.activate();
  },
  watch: {},
  props: {},
  newd() {},
  components: {},
  data() {
    return {
      loading: false,
      activated: false,
    };
  },
  methods: {
    activate() {
      let f = {
        id: this.$route.params.id,
        slug: this.$route.params.slug,
        param: this.$route.params.param,
      };
      console.log(f);
      this.loading = true;
      this.api("activte-exhibitor-user-mail", f)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            Swal.fire({
              title: "Email Verified",
              html: "Contact Person email has been verified successfully.<br />You can now login and create additional users for your company",
              type: "info",
              allowOutsideClick: false,
              allowEscapeKey: false,
              confirmButtonColor: "#5daf34",
              cancelButtonColor: "#d33",
              showCancelButton: false,
              confirmButtonText: "OK",
            }).then((result) => {
              if (result.value) {
                document.location.href = "/sign-in";
              }
            });
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "ContactActivateEmail",
};
</script>
<style>
.el-form-item {
  text-align: left;
}
</style>
